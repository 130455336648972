import React, { useState, useEffect, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon, LatLngBounds } from "leaflet";
import { Breadcrumbs, Typography } from "@mui/material";

const MapPage = () => {
  const { id } = useParams(); // Ensure this matches your route
  const [locations, setLocations] = useState([]);
  const [newLocation, setNewLocation] = useState(null);
  const mapRef = useRef(null);

  useEffect(() => {
    const fetchUserLocations = async () => {
      const url = `${
        process.env.REACT_APP_API_URL
      }/getLocation?id=${encodeURIComponent(id)}`;

      try {
        const authToken = localStorage.getItem("authToken");

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        setLocations(result.locations || []);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchUserLocations();
  }, [id]);

  const handleMapClick = (event) => {
    const { lat, lng } = event.latlng;
    setNewLocation([lat, lng]);
    setLocations([...locations, { lat, long: lng }]);
  };

  const getPolylinePositions = () => {
    return locations.map((location) => [location.lat, location.long]);
  };

  const getMarkerPositions = () => {
    if (locations.length === 0) return [];
    return [locations[0], locations[locations.length - 1]];
  };

  useEffect(() => {
    if (locations.length > 0) {
      const newBounds = new LatLngBounds(
        locations.map((location) => [location.lat, location.long])
      );

      if (mapRef.current) {
        mapRef.current.fitBounds(newBounds);
      }
    }
  }, [locations]);

  // Custom Hook to access map instance
  const MapEventHandler = () => {
    const map = useMap();
    useEffect(() => {
      mapRef.current = map;
    }, [map]);

    return null;
  };

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb" style={{ margin: "16px" }}>
        <Link to="/">Dashboard</Link>
        <Typography color="textPrimary">Map</Typography>
      </Breadcrumbs>
      <MapContainer
        center={[0, 0]} // Placeholder center
        zoom={13} // Default zoom
        style={{ height: "70vh", width: "100%" }}
        onClick={handleMapClick}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="© OpenStreetMap contributors"
        />
        <MapEventHandler />
        {getMarkerPositions().map((location, index) => (
          <Marker key={index} position={[location.lat, location.long]}>
            <Popup>
              {index === 0 ? "Start" : "End"} Marker
              <br />
              Lat: {location.lat}, Long: {location.long}
            </Popup>
          </Marker>
        ))}
        {newLocation && (
          <Marker
            position={newLocation}
            icon={
              new Icon({
                iconUrl:
                  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
                shadowUrl:
                  "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
                shadowSize: [41, 41],
              })
            }>
            <Popup>
              New Marker
              <br />
              Lat: {newLocation[0]}, Long: {newLocation[1]}
            </Popup>
          </Marker>
        )}
        {locations.length > 1 && (
          <Polyline positions={getPolylinePositions()} color="blue" />
        )}
      </MapContainer>
    </div>
  );
};

export default MapPage;
