// src/components/PrivateRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext";

function PrivateRoute({ element }) {
  const { authToken } = useContext(AuthContext);

  return authToken ? element : <Navigate to="/login" />;
}

export default PrivateRoute;
