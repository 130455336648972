// src/routes/AppRoutes.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import MapPage from "../pages/MapPage";
import Login from "../pages/Login";
import PrivateRoute from "../components/PrivetRoutes/PrivetRoutes";
// import MainLayout from "../components/Layout/MainLayout"; // Import the Layout component

function AppRoutes() {
  return (
    <Routes>
      {/* <Route element={<MainLayout />}> */}
      <Route path="/" element={<PrivateRoute element={<Dashboard />} />} />
      <Route
        path="/Maps/:id"
        element={<PrivateRoute element={<MapPage />} />}
      />
      {/* </Route> */}
      <Route path="/login" element={<Login />} />
    </Routes>
  );
}

export default AppRoutes;
