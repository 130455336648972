import React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledDrawer = styled(Drawer)({
  width: 200,
  //   flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: 200,
    // boxSizing: "border-box",
  },
});

const StyledListItem = styled(ListItem)(({ theme, isActive }) => ({
  textDecoration: "none",
  color: "inherit",
  backgroundColor: isActive ? "#f0f0f0" : "transparent",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Logo = styled("h1")(({ theme }) => ({
  textAlign: "center",
  margin: theme.spacing(2, 0),
  fontSize: "1.5rem",
  color: theme.palette.primary.main,
}));

function Sidebar() {
  const location = useLocation();

  return (
    <StyledDrawer variant="permanent">
      <Logo>Tracking Display</Logo>
      <List>
        <StyledListItem
          button
          component={Link}
          to="/"
          isActive={location.pathname === "/" ? 1 : 0}>
          <ListItemText primary="Dashboard" />
        </StyledListItem>
      </List>
    </StyledDrawer>
  );
}

export default Sidebar;
