import React from "react";
import { useLocation } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import Header from "./components/Layout/Header";
import Sidebar from "./components/Layout/Sidebar";
import Footer from "./components/Layout/Footer";
import Box from "@mui/material/Box";

function App() {
  const location = useLocation();

  const isLoginPage = location.pathname === "/login";

  return (
    <Box sx={{ display: "flex" }}>
      {!isLoginPage && <Sidebar />}
      <Box sx={{ flex: 1 }}>
        {!isLoginPage && <Header />}
        <Box component="main" sx={{ p: 3 }}>
          <AppRoutes />
        </Box>
        {!isLoginPage && <Footer />}
      </Box>
    </Box>
  );
}

export default App;
