import React, { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode"; // Correct import statement

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [authToken, setAuthToken] = useState(() => {
    // Retrieve token from localStorage or set to null
    return localStorage.getItem("authToken") || null;
  });
  const [user, setUser] = useState(() => {
    // Decode token to get user info
    return authToken ? jwtDecode(authToken) : null;
  });

  useEffect(() => {
    if (authToken) {
      setUser(jwtDecode(authToken));
    } else {
      setUser(null);
    }
  }, [authToken]);

  const login = (token) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    setAuthToken(null);
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ authToken, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
