import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function Footer() {
  return (
    <Box sx={{ textAlign: "center", p: 2, backgroundColor: "#f1f1f1" }}>
      <Typography variant="body2" color="textSecondary">
        © 2024 iMaker
      </Typography>
    </Box>
  );
}

export default Footer;
